<template>
  <section class="org-list">
    <a-table ref="myApplicationListRef" :columns="roleName === 'Group Lead' ? groupLeadColumns : orginColumns"
      :data-source="myApplicationList" rowKey="id" :scroll="{
        x: 1200,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }" :pagination="pagination">
      <template #status="{ record }">
        <a-tag :class="`status ${seatMyUserNameStatus(record.status)}`">
          {{ seatMyUserNameStatus(record.status) }}
        </a-tag>
      </template>
      <template #userName="{ text }">
        <a-popover placement="bottom">
          <template #content>
            {{ text }}
          </template>
          <span>
            <span>{{ text ? text.split(";")[0] + ';' : null }}</span>
            <span v-if="text ? text.includes(';') : false" class="userName">+{{ text.split(";").length - 1 }}...</span>
          </span>
        </a-popover>
      </template>
      <template #usagetime="{ record }">
        {{ record.startDate }}{{ ' 至 ' }}{{ record.endDate }}
      </template>
      <template #rejectReason="{ text }">
        <div v-if="!text">{{ '--' }}</div>
        <div v-else style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;">
          <a-popover placement="bottom">
            <template #content>
              {{ text }}
            </template>
            <span>
              <span>{{ text }}</span>
            </span>
          </a-popover>
        </div>
      </template>
      <template #sqrName="{ record }">
        <div>{{ record.sqrName }}</div>
        <div>{{ record.sqrEmail }}</div>
      </template>
      <template #action="{ record }">
        <ActionBtn :recordData="record" :userNameValue="userNameValue" v-bind="record" :btnList="btnList" @EyeOutlined="handleDetails(record)"
          @MinusCircleOutlined="handleCancel(record)" @FormOutlined="handleModify(record)"
          @HistoryOutlined="handleExtension(record)" />
      </template>
    </a-table>
    <MyApplicationDetail :visible="detailVisible" :userNameValue="userNameValue" :detailData="detailData" @close-modal="closeSaveModal"
      @save-modal="saveClick"></MyApplicationDetail>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" title="正在取消申请" />
  </section>
</template>

<script>
import ActionBtn from "@/components/ActionBtn.vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { nextTick, onBeforeMount, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { formatTableCol, useTableHeight } from "@/utils/common";
import { seatMyUserNameStatus } from "@/utils/formatStatus";
import MyApplicationDetail from "@/views/home/Seat/MyApplication/components/MyApplicationDetail.vue";
import ModalBox from "@/components/ModalBox.vue";
import { message } from "ant-design-vue";
import { useRouter } from 'vue-router/dist/vue-router'
import { useRoute } from "vue-router";
import { useStore } from "vuex";


const btnList = [
  {
    name: '详情',
    icon: 'EyeOutlined',
    auth: ['system:my:application:details']
  },
  {
    name: '取消',
    icon: 'MinusCircleOutlined',
    auth: ['system:my:application:cancel']
  },
  {
    name: '修改',
    icon: 'FormOutlined',
    auth: ['system:my:application:modify']
  },
  {
    name: '延期',
    icon: 'HistoryOutlined',
    auth: ['system:my:application:extension']
  },
]

export default {
  name: "MyApplicationList",
  methods: { seatMyUserNameStatus, formatTableCol },
  components: { ModalBox, MyApplicationDetail, ActionBtn },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const router = useRouter();
    let userNameValue = ref()
    let roleName = ref()
    const store = useStore();
    userNameValue.value = store.state.app.user.fullName
    const myApplicationListRef = ref();
    let orginColumns = [
      {
        title: "申请单号",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 140,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 120,
        slots: { customRender: "status" },
      },
      {
        title: "Group Lead",
        dataIndex: "groupLeanNameEmail",
        key: "groupLeanNameEmail",
        ellipsis: true,
        width: 180,
        customRender: ({ text }) => (
          <div>
            <div>{text ? text.split(";")[0] : null}</div>
            <div>{text ? text.split(";")[1] : null}</div>
          </div>
        ),
        customCell: () => {
          return {
            style: {
              lineHeight: 1.2,
              padding: '6px 10px',
            }
          }
        }
      },
      {
        title: "提交时间",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "座位使用人",
        dataIndex: "userName",
        key: "userName",
        ellipsis: true,
        width: 150,
        slots: { customRender: "userName" },
      },
      {
        title: "使用时间",
        dataIndex: "usagetime",
        key: "usagetime",
        ellipsis: true,
        width: 200,
        slots: { customRender: "usagetime" },
      },
      {
        title: "City",
        dataIndex: "cityName",
        key: "cityName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Building",
        dataIndex: "buildingName",
        key: "buildingName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Floor",
        dataIndex: "floorName",
        key: "floorName",
        ellipsis: true,
        width: 80,
      },
      {
        title: "项目组",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 100,
      },
      {
        title: "历史驳回原因",
        dataIndex: "rejectReason",
        key: "rejectReason",
        ellipsis: true,
        width: 120,
        slots: { customRender: "rejectReason" },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
        fixed: "right",
        width: 100,
      },
    ];
    let groupLeadColumns = [
      {
        title: "申请单号",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 140,
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        ellipsis: true,
        width: 110,
        slots: { customRender: "status" },
      },
      {
        title: "Group Lead",
        dataIndex: "groupLeanNameEmail",
        key: "groupLeanNameEmail",
        ellipsis: true,
        width: 180,
        customRender: ({ text }) => (
          <div>
            <div>{text ? text.split(";")[0] : null}</div>
            <div>{text ? text.split(";")[1] : null}</div>
          </div>
        ),
        customCell: () => {
          return {
            style: {
              lineHeight: 1.2,
              padding: '6px 10px',
            }
          }
        }
      },
      {
        title: "申请人",
        dataIndex: "sqrName",
        key: "sqrName",
        ellipsis: true,
        width: 190,
        slots: { customRender: "sqrName" },
        customCell: () => {
          return {
            style: {
              lineHeight: 1.2,
              padding: '6px 10px',
            }
          }
        }
      },
      {
        title: "提交时间",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "座位使用人",
        dataIndex: "userName",
        key: "userName",
        ellipsis: true,
        width: 200,
        slots: { customRender: "userName" },
      },
      {
        title: "使用时间",
        dataIndex: "usagetime",
        key: "usagetime",
        ellipsis: true,
        width: 180,
        slots: { customRender: "usagetime" },
      },
      {
        title: "City",
        dataIndex: "cityName",
        key: "cityName",
        ellipsis: true,
        width: 70,
      },
      {
        title: "Building",
        dataIndex: "buildingName",
        key: "buildingName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Floor",
        dataIndex: "floorName",
        key: "floorName",
        ellipsis: true,
        width: 80,
      },
      {
        title: "项目组",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 100,
      },
      {
        title: "历史驳回原因",
        dataIndex: "rejectReason",
        key: "rejectReason",
        ellipsis: true,
        width: 120,
        slots: { customRender: "rejectReason" },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: { customRender: "action" },
        fixed: "right",
        width: 100,
      },
    ]
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12', '20', '30', '40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const myApplicationList = ref();
    let searchCondition = {};
    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        roleName: roleName.value,
        ...searchParams,
      };
      $api.selectApplication(params).then((res) => {
        pagination.total = res.data.total;
        myApplicationList.value = res.data.resource;
        detailData.value = myApplicationList.value[0]
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const detailData = ref({})
    const detailVisible = ref(false)
    const closeSaveModal = () => {
      detailVisible.value = false
    }
    const saveClick = (record) => {
      detailVisible.value = false
      sessionStorage.setItem('actionFlag', 1)
      sessionStorage.setItem('isDetailFlag', 1)
      getDetailsInfo(record.id)
    }

    const handleDetails = (record) => {
      detailData.value = record
      detailVisible.value = true
    }
    const handleCancel = async(record) => {
      let result = await $api.seatVerifyMySeatButton({applicationId:record.id})
      if(result.data !== true){
        message.error("当前用户无此权限，请刷新获取最新单据");
        return
      }
      myApplicationRecord.value = record
      content.value = '此申请单中的全部座位将被重新释放?'
      showIconCom.value = ''
      modelValue.value = true
    }
    const handleModify = async(record) => {
      let result = await $api.seatVerifyMySeatButton({applicationId:record.id})
      if(result.data !== true){
        message.error("当前用户无此权限，请刷新获取最新单据");
        return
      }
      sessionStorage.setItem('actionFlag', 1)
      getDetailsInfo(record.id)
    }
    const handleExtension = async(record) => {
      let result = await $api.seatVerifyMySeatButton({applicationId:record.id})
      if(result.data !== true){
        message.error("当前用户无此权限，请刷新获取最新单据");
        return
      }
      sessionStorage.setItem('actionFlag', 2)
      getDetailsInfo(record.id)
    }
    const getDetailsInfo = (id) => {
      console.log('2', id)
      $api.getApplicationInfo({ id: id }).then((res) => {
        sessionStorage.setItem('seatInfoData', JSON.stringify(res.data))
        router.push({
          path: "/seat/application",
          query: {
            groupLead: res.data.groupLead,
            id: id
          }
        });
      });
    }

    const myApplicationRecord = ref({})

    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const handleOk = () => {
      $api.cancelSeatApplication({ applicationId: myApplicationRecord.value.id }).then((res) => {
        if (res.code === 200) {
          message.success(res.msg);
          modelValue.value = false
          doSearch(searchCondition);
        }
      });
    }

    const route = useRoute();
    watch(
      () => route.query.flag,
      (val) => {
        if (val && val === '1') {
          //detailData.value = { id: route.query.id }
          detailVisible.value = true
        }
        doSearch()
      },
    )

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);
    onBeforeMount(() => {
      roleName.value = store.state.app.defaultRole ? store.state.app.defaultRole : localStorage.getItem("switchRoleName")
    })
    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      myApplicationListRef,
      pagination,
      myApplicationList,
      searchClick,
      tableHeight,
      doSearch,
      btnList,
      handleDetails,
      handleCancel,
      handleModify,
      handleExtension,
      getDetailsInfo,
      detailVisible,
      closeSaveModal,
      detailData,
      saveClick,
      modelValue,
      content,
      showIconCom,
      handleOk,
      myApplicationRecord,
      orginColumns,
      groupLeadColumns,
      roleName,
      userNameValue
    };
  },
}
</script>

<style scoped lang="less">
.org-list {

  .status {
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.待审批,
    &.改期待审批 {
      color: #1890FF;
      background: #DBEEFF;
    }

    &.已通过 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }

    &.已过期 {
      color: #999999;
      background: #CCCCCC 60%;
    }

    &.已取消 {
      color: #999999;
      background: #CCCCCC 60%;
    }

    &.待操作 {
      color: #DC5E4F;
      background: #FFE7EC;
    }



  }

  .userName {
    color: #3182CE;
    background: #E9F6FE;
    margin-left: 0.3vw;
    padding: 0 0.2vw
  }
}
</style>
