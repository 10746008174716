<template>
  <div ref="myApplicationRef">
    <a-modal
        :getContainer="() => $refs.myApplicationRef"
        class="myApplicationModal"
        :visible="visible"
        @cancel="closeModal"
        width="62.5vw"
        :zIndex="1000"
        :title="'申请编号：'+formState.id"
        centered
    >
    <div class="receiptStatus" :style="{  'left': `${formState.id?.length * 0.5 + 10}vw`}">
      <a-tag :class="`status ${seatUserNameStatus(formData.applicationStatus)}`">
          {{seatUserNameStatus(formData.applicationStatus)}}
        </a-tag>
    </div>
      <template #footer>
        <a-space>
          <a-button @click="closeModal" type="primary" ghost>返回</a-button>
          <a-button @click="handelSave" type="primary" v-show="['1','2','0','6'].includes(formData.applicationStatus) && typeShowButtion && isShowButton">修改申请</a-button>
        </a-space>
      </template>
      <div>
        <div>
          <div class="warding" v-if="formState.Type && formState.Type === '1'">
              <ExclamationCircleOutlined />&nbsp;&nbsp;您的申请人角色权限已被移除，如需编辑，请联系新申请人。
            </div>
            <div class="warding" v-if="formState.Type && formState.Type === '2'">
              <ExclamationCircleOutlined />&nbsp;&nbsp;您的Group Lead 角色权限已被移除。
            </div>
            <div class="warding" v-if="formState.Type && formState.Type === '3'">
              <ExclamationCircleOutlined />&nbsp;&nbsp;您的申请人角色权限以及Group Lead角色权限已被移除，如需编辑，请联系新的申请人
            </div>
          <CardTitle title="基础信息"/>
          <div class="infos">
            <a-row class="info">
              <a-col :span="3"><span class="indo-lable">项目组：</span></a-col>
              <a-col :span="21"><span class="indo-lable" style="color: #4D4D4D;font-weight: 600">{{formData.engagementDescription}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">申请人：</span></a-col>
              <a-col :span="10"><span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{formData.fullName}}</span>{{' ('}}{{formData.email}}{{')'}}</span></a-col>
              <a-col :span="2"><span class="indo-lable">提交时间：</span></a-col>
              <a-col :span="9"><span class="indo-lable" style="color: #4D4D4D">{{formData.operationTime}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">Group Lead：</span></a-col>
              <a-col :span="10"><span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{formData.groupLeanFullName}}</span>{{' ('}}{{formData.groupLeanEmail}}{{')'}}</span></a-col>
              <a-col :span="2"><span class="indo-lable">使用时间：</span></a-col>
              <a-col :span="9"><span class="indo-lable" style="color: #4D4D4D">{{formData.startDate}}{{' 至 '}}{{formData.endDate}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">Office：</span></a-col>
              <a-col :span="21"><span class="indo-lable" style="color: #4D4D4D">{{formData.office}}</span></a-col>
              <a-col :span="3"><span class="indo-lable">座位位置：</span></a-col>
              <a-col :span="10">
                <div v-if="formData.seatApplicationUserCommandList.length > 0" class="seat-box">
                  <img src="@/assets/images/xindiCenter56.png" width="60" class="seat-box-img" @click="checkSeatInfo" v-if="formData.floor === 'floor01'">
                  <img src="@/assets/images/wanke05.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor04'">
                  <img src="@/assets/images/wanke04.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor03'">
                  <img src="@/assets/images/wanke03.png" width="120" height="40" class="seat-box-img" @click="checkSeatInfo" v-else-if="formData.floor === 'floor02'">
                </div>
              </a-col>
              <a-col :span="2"><span class="indo-lable"><div v-if="formData.fileId">审批文件：</div></span></a-col>
              <a-col :span="9">
                <div v-if="formData.fileId" class="indo-lable-file">
                  <span>
                    <FileExclamationOutlined class="icon"/>
                    <span style="color: #4D4D4D;margin-left: 0.5vw">{{formData.fileName}}</span>
                  </span>
                  <span style="margin-right: 2vw" @click="downloadClick"><DownloadOutlined class="icon"/></span>
                </div>
              </a-col>
              <a-col :span="3"><span class="indo-lable">备注：</span></a-col>
              <a-col :span="21">
                <Tooltip :title="formData.reason" placement="bottomLeft">
                  <span class="indo-lable-reason">{{formData.reason}}</span>
                </Tooltip>
              </a-col>
            </a-row>
          </div>
        </div>
        <div>
          <CardTitle title="座位申请详情"/>
          <div class="seat-code">
            <div v-for="(item,index) in formData.seatApplicationUserCommandList" :key="index" class="seat-code-info">
              <span class="indo-lable">座位编号：</span><span class="indo-lable" style="color: #4D4D4D">{{item.seatCode}}</span>
              <span class="indo-lable" style="margin-left: 1vw">使用人：</span>
              <span class="indo-lable" style="color: #4D4D4D"><span style="color: #1890FF;font-weight: 600">{{item.userFullName}}</span>
                <span v-show="item.userFullName !== 'TBD' && item.userFullName">{{' ('}}{{item.userEmail}}{{')'}}</span>
              </span>
            </div>
          </div>
        </div>
        <div v-if="formData.seatReviewHistoryCommandList.length > 0 && !['0','6'].includes(formData.applicationStatus)">
          <CardTitle title="审批记录"/>
          <div v-for="(item,index) in formData.seatReviewHistoryCommandList" :key="index" class="review-history">
            <div :style="`color: ${item.status === '1'? '#58B99E': '#DC5E4F'}`">
              <span style="font-size: 1vw">●</span>
              <span class="status">{{item.status === '1'? '审核已通过' : '审批被驳回'}}</span>
              <span style="margin-left:1vw;font-weight:0.8rem;color:black" v-if="item.updateStatus && item.updateEnd">驳回内容：修改使用时间为{{ item.updateStatus }} 至 {{ item.updateEnd }}</span>
            </div>
            <div class="segment">|</div>
            <div class="history-info">
              <TeamOutlined class="icon" />
              <span style="color: #3182CE"><span style="font-weight: 600;margin-left: 0.4vw">{{item.approverFullName}}</span>{{' ('}}{{item.approverEmail}}{{')'}}</span>
              <span class="segment-icon">|</span>
              <img src="@/assets/images/ScheduleOndutytime.png" class="icon img"/>
              <span style="margin-left: 0.4vw;color: #4D4D4D99;">{{item.operationTime}}</span>
            </div>
            <div class="history-info" style="color: #4D4D4D99;">{{item.reason}}</div>
          </div>
        </div>
      </div>
    </a-modal>
    <CheckSeatPositions
        :visible="seatPositionsVisible"
        :selectedData="formData.seatApplicationUserCommandList"
        :formState="formData"
        @close-modal="closeSeatPositions"
    ></CheckSeatPositions>
  </div>
</template>

<script>
import {seatUserNameStatus} from "@/utils/formatStatus";
import {computed, ref, watch} from "vue";
import CardTitle from "@/components/CardTitle.vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import {
  TeamOutlined,
  FileExclamationOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons-vue";
import {downloadFile} from "@/utils/common";
import Tooltip from "@/components/Tooltip.vue";
import CheckSeatPositions from "@/views/home/Seat/components/CheckSeatPositions.vue";
import { message } from "ant-design-vue";
export default {
  name: "MyApplicationDetail",
  emits: ["close-modal", "save-modal"],
  methods: {seatUserNameStatus},
  components: {
    CheckSeatPositions,
    Tooltip,
    CardTitle,
    TeamOutlined,
    FileExclamationOutlined,
    DownloadOutlined,
    ExclamationCircleOutlined
  },
  props: {
    visible: Boolean,
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    userNameValue:{
      type:String,
      default:''
    },
  },
  setup(props,{ emit }) {
    const { $api } = useGlobalPropertyHook();
    const formState = computed(() => props.detailData)
    const formData = ref({
      seatApplicationUserCommandList: [],
      seatReviewHistoryCommandList: [],
    })
    const isShowButton = ref(true)
    // 1,2,3 不显示按钮 ， 当type为2 时候当登录人和申请人相同的时候显示按钮
    let typeShowButtion = ref(true)
    const getDetailsInfo = () => {
      console.log('1',formState.value.id)
      $api.getApplicationInfo({id:formState.value.id}).then((res) => {
        if(props.userNameValue !== res.data.fullName && props.userNameValue === res.data.groupLeanFullName){
          isShowButton.value = false
        } else {
          isShowButton.value = true
        }
        if(res.data.type && ['1','2','3'].includes(res.data.type)){
          typeShowButtion.value = false
        }
        if(res.data.type && res.data.type === '2' && res.data.fullName === props.userNameValue){
          typeShowButtion.value = true
        }
        formData.value = res.data;
      });
    }

    const downloadClick = () => {
      $api.downloadSeatFile(formData.value.fileId).then((res) => {
        downloadFile(res.file, formData.value.fileName);
      });
    }

    const seatPositionsVisible = ref(false)
    const checkSeatInfo = () => {
      seatPositionsVisible.value = true
    }
    const closeSeatPositions = () => {
      seatPositionsVisible.value = false
    }
    const closeModal = () => {
      emit("close-modal");
    };

    const handelSave = async () => {
      let result = await $api.seatVerifyMySeatButton({applicationId:formState.value.id})
      if(result.data !== true){
        message.error("当前用户无此权限，请刷新获取最新单据");
        return
      }
      emit("save-modal",formData.value);
    };

    watch(
        () => props.visible,
        (val) => {
          if(val) {
            getDetailsInfo()
          } else {
            formData.value = {}
            formData.value.seatApplicationUserCommandList = []
            formData.value.seatReviewHistoryCommandList = []
          }
        }
    )

    return {
      formState,
      formData,
      closeModal,
      handelSave,
      getDetailsInfo,
      downloadClick,
      checkSeatInfo,
      seatPositionsVisible,
      closeSeatPositions,
      isShowButton,
      typeShowButtion
    }
  }
}
</script>

<style scoped lang="less">
.myApplicationModal{
  .infos {
    margin: 1.25vw 0;
    background: #FCFCFC;
    .info {
      padding: 16px;
      .indo-lable {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
      }
      .indo-lable-file {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
      }
      .indo-lable-reason {
        font-size: 16px;
        color: #4D4D4D;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
      .icon{
        color: #3182CE;
        font-size: 1vw;
        cursor: pointer;
      }
      .seat-box {
        .seat-box-img {
          cursor: pointer;
        }
      }
    }
  }
  .warding {
    color:red;
    line-height:1.2;
    position:relative;
    font-size:0.7rem;
    bottom:14px
  }
  .receiptStatus {
    position: absolute;
    top:17px;
    .status{
    font-size: 0.729vw;
    padding: 0.1vw 0.86vw;
    border: 0;

    &.待审批, &.改期待审批 {
      color: #1890FF;
      background: #DBEEFF;
    }
    &.已通过 {
      color: #58B99E;
      background: #DDF8F0 60%;
    }
    &.已过期 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已取消 {
      color: #999999;
      background: #CCCCCC 60%;
    }
    &.已驳回 {
      color: #DC5E4F;
      background: #FFE7EC;
    }
  }
  }
  .seat-code {
    margin: 1.25vw 0;
    background: #FCFCFC;
    max-height: 7.5vw;
    overflow-y: auto;

    .seat-code-info {
      padding: 0.4vw 0.8vw 0.2vw 0.8vw;

      .indo-lable {
        font-size: 16px;
        color:#4D4D4D99;
        font-weight: 400;
      }
    }
  }

  .review-history{
    position: relative;
    margin-top: 1.25vw;

    .status {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      margin-left: 0.8vw;
    }
    .segment {
      font-size: 2.8vw;
      color: #EBEBEB;
      position: absolute;
      top: 0.5vw;
      left: -0.05vw;
    }
    .history-info{
      margin-left: 1.4vw;
      margin-top: 0.3vw;
      font-size: 0.79vw;
    }
    .segment-icon {
      margin: 0 1vw;
      color: #3182CE;
    }

    .img {
      width: 1vw;
      height: 0.9vw;
      cursor: pointer;
      margin-top: -0.2vw;
    }
    .icon {
      color: #3182CE;
    }
  }
}
:deep(.ant-col){
  margin-bottom: 10px;
}
:deep(.ant-col-3) {
  max-width: 9.5%;
}
:deep(.ant-modal-body){
  max-height: 80vh;
  overflow-y: auto;
}
</style>
