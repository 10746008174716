<template>
  <section class="app-container" ref="myApplicationRef">
    <Filter
        @search="handleSearch"
        @refresh-table="handleResetTable"
        @refresh-table-height="handleResetTableHeight"
    >
    </Filter>
    <MyApplicationList ref="myApplicationListRef"></MyApplicationList>
  </section>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import Filter from "@/views/home/Seat/MyApplication/components/Filter.vue";
import MyApplicationList from "@/views/home/Seat/MyApplication/components/MyApplicationList.vue";
import { useGlobalPropertyHook } from '@/hooks/common'
export default defineComponent({
  name: "myApplication",
  components: {
    MyApplicationList,
    Filter
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const myApplicationListRef = ref();
    const handleSearch = (searchParams) => {
      myApplicationListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      myApplicationListRef.value.doSearch();
    };
    const handleResetTableHeight = () => {
      myApplicationListRef.value.getSize();
    };
    onMounted(()=>{
      if(JSON.parse(sessionStorage.getItem('seatSessionSelectOption')) ==null){
        queryBaseData()
      }
    })
    const originList = {}
    const queryBaseData = () => {
      $api.queryBaseData({ "types": ["city", "work_location", "floor"] }).then((res) => {
        const data = res.data
        let city = data.filter((item) => { return item.type == "city" })
        let building = data.filter((item) => { return item.type == 'work_location' })
        let floor = data.filter((item) => { return item.type == 'floor' })
        handlerSelectOption(city, building, floor)
      });
    };
    // 注意使用下列函数格式化时候，传入的顺序不能变
    const handlerSelectOption = (city, building, floor) => {
      let mapData = (data) => {
        return data.map((item) => {
          return {
            ...item,
            value: item.code,
            label: item.name
          }
        })
      }
      originList['city'] = mapData(city)
      originList['building'] = mapData(building)
      originList['floor'] = mapData(floor)
      // 把所有下拉关系保存一份
      sessionStorage.setItem('seatSessionSelectOption', JSON.stringify(originList))
    }
    return {
      myApplicationListRef,
      handleSearch,
      handleResetTable,
      handleResetTableHeight,
    };
  },
});
</script>
