<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef"
        @doCollapseEffect="getChartContainerStyle" @showMoreForm="showMoreForm" :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList">
        <template #customform>
          <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
            <template v-for="(item, index) in queryList" :key="index">
              <template v-if="index <= 4">
                <a-form-item :label="getItemCode(item.code)" name="name" class="rms-form-item">
                  <template v-if="item.type === '3'">
                    <a-input v-model:value="formState[item.inputDataType]"
                      :placeholder="getPlaceholder(item.inputDataType)" allowClear autocomplete="off" />
                  </template>
                  <template v-if="item.type === '5'">
                    <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                      showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)"
                      @update:value="(value) => changeSelectOption(value, item.inputDataType)"
                      :disabled="disabledOption(item.inputDataType)">
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type === '6'">
                    <a-range-picker v-model:value="formState.seatApplicationTime" :placeholder="['开始时间', '结束时间']"
                      format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :mode="mode" :open="isOpen" @openChange="openChange"
                      @panelChange="handlePanelChange" />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm"
              @moreFormSearch="moreSeachHanlder">
              <template v-for="(item, index) in queryList" :key="index">
                <template v-if="index > 4">
                  <a-form-item :label="getItemCode(item.code)" name="name" class="rms-form-item">
                    <template v-if="item.type === '3'">
                      <a-input v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceholder(item.inputDataType)" allowClear autocomplete="off" />
                    </template>
                    <template v-if="item.type === '5'">
                      <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择" mode="multiple"
                        showArrow :maxTagCount="1" allowClear :options="getOptions(item.inputDataType)"
                        @update:value="(value) => changeSelectOption(value, item.inputDataType)"
                        :disabled="disabledOption(item.inputDataType)">
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type === '6'">
                      <a-range-picker v-model:value="formState.seatApplicationTime" :placeholder="['开始时间', '结束时间']"
                        format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :mode="mode" :open="isOpen" @openChange="openChange"
                        @panelChange="handlePanelChange" />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>

          <!--          <a-form-->
          <!--              ref="formRef"-->
          <!--              class="form rms-form"-->
          <!--              layout="vertical"-->
          <!--              :model="formState"-->
          <!--          >-->
          <!--            <a-form-item label="Group Lead" name="groupLead" class="rms-form-item">-->
          <!--              <a-input-->
          <!--                  v-model:value="formState.groupLead"-->
          <!--                  placeholder="Name/ID Number"-->
          <!--                  allowClear-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--            <a-form-item label="申请单号" name="seatApplicationId" class="rms-form-item">-->
          <!--              <a-input-->
          <!--                  v-model:value="formState.seatApplicationId"-->
          <!--                  placeholder="请输入"-->
          <!--                  allowClear-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--            <a-form-item label="座位使用人" name="condition" class="rms-form-item">-->
          <!--              <a-input-->
          <!--                  v-model:value="formState.condition"-->
          <!--                  placeholder="Name/ID Number"-->
          <!--                  allowClear-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--            <a-form-item label="状态" name="seatApplicationStatus" class="rms-form-item">-->
          <!--              <SelectWithAll-->
          <!--                  v-model:value="formState.seatApplicationStatus"-->
          <!--                  placeholder="请选择"-->
          <!--                  mode="multiple"-->
          <!--                  showArrow-->
          <!--                  allowClear-->
          <!--                  :maxTagCount="1"-->
          <!--                  :options="seatApplicationStatusOps"-->
          <!--              >-->
          <!--              </SelectWithAll>-->
          <!--            </a-form-item>-->
          <!--            <a-form-item label="使用时间" name="Date" class="rms-form-item">-->
          <!--              <a-range-picker-->
          <!--                  v-model:value="formState.Date"-->
          <!--                  :placeholder="['开始时间', '结束时间']"-->
          <!--                  format="YYYY-MM-DD"-->
          <!--                  valueFormat="YYYY-MM-DD"-->
          <!--                  :mode="mode"-->
          <!--                  :open="isOpen"-->
          <!--                  @openChange="openChange"-->
          <!--                  @panelChange="handlePanelChange"-->
          <!--              />-->
          <!--            </a-form-item>-->
          <!--          </a-form>-->
        </template>
      </FilterLayout>
      <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal"
        @reset="queryFormList"></FormModalBox>
    </div>
  </section>
</template>
<script>
import {
  defineComponent, onActivated, onMounted,
  reactive,
  ref, watch,
} from "vue";
import { cloneDeep } from "lodash";
import MoreFormList from "@/components/MoreFormList.vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  name: "Filter",
  components: { MoreFormList, FormModalBox },
  emits: ["search", "refresh-table", "refresh-table-height"],
  props: {},
  setup(props, ctx) {
    let initData = {
      groupLead: '',
      seatApplicationId: "",
      condition: "",
      seatApplicationStatus: [],
      seatApplicationTime: [],
      seatApplicationEndTime: undefined,
      seatApplicationBeginTime: undefined,
      city: undefined,
      building: undefined,
      floor: undefined
    }
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({ ...initData });
    const formRef = ref();
    const seatApplicationStatusOps = ref([
      {
        value: 0,
        label: "待审批",
      },
      {
        value: 1,
        label: "已通过",
      },
      {
        value: 2,
        label: "待操作",
      },
      {
        value: 3,
        label: "已过期",
      },
      {
        value: 4,
        label: "已取消",
      },
      {
        value: 6,
        label: "改期待审批",
      },
    ]);
    const changeSelectOption = (item, type) => {
      let allArr = [...originList.city, ...originList.building, ...originList.floor]
      // 判断下拉的是building还是location还是floor,清空watch监听不然没办法判断type
      if (['city', 'building', 'floor'].includes(type)) {
        if (item[0]) {
          let type = allArr.find((jtem) => { return jtem.value == item[0] }).type
          if (type == 'work_location') {
            type = 'building'
          }
          if (type == 'city') {
            optionList.building = originList.building.filter((jtem) => { return Object.values(formState[type]).includes(jtem.uplevel) })
            if (formState.building) {
              let allId = optionList.building.map((jtem) => { return jtem.code })
              formState.building = formState.building.filter((jtem) => { return allId.includes(jtem) })
            }
          }
          if (type == 'building') {
            optionList.floor = originList.floor.filter((jtem) => { return Object.values(formState[type]).includes(jtem.uplevel) })
            if (formState.floor) {
              let allId = optionList.floor.map((jtem) => { return jtem.code })
              formState.floor = formState.floor.filter((jtem) => { return allId.includes(jtem) })
            }
          }
        }
      }

    }
    watch(
      () => formState.building,
      (val) => {
        if (val && val.length == 0) {
          optionList.floor = []
          formState.floor = []
        }
      }
    )
    watch(
      () => formState.city,
      (val) => {
        if (val && val.length == 0) {
          optionList.building = []
          formState.building = []
          optionList.floor = []
          formState.floor = []
        }
      }
    )

    const resetForm = () => {
      Object.assign(formState, initData)
      formState.city = []
      optionList.building = []
      formState.building = []
      optionList.floor = []
      formState.floor = []
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    // 下拉筛选全部List
    const originList = {}
    // 下拉筛选动态下拉框
    const optionList = reactive({
      city: [],
      building: [],
      floor: []
    })
    const getOptionHandler = async () => {
      let res = await $api.queryBaseData({ "types": ["city", "work_location", "floor"] })
      const data = res.data
      let city = data.filter((item) => { return item.type == "city" })
      let building = data.filter((item) => { return item.type == 'work_location' })
      let floor = data.filter((item) => { return item.type == 'floor' })
      handlerSelectOption(city, building, floor)
    }
    // 注意使用下列函数格式化时候，传入的顺序不能变
    const handlerSelectOption = (city, building, floor) => {
      let mapData = (data) => {
        return data.map((item) => {
          return {
            ...item,
            value: item.code,
            label: item.name
          }
        })
      }
      originList['city'] = mapData(city)
      optionList['city'] = mapData(city)
      originList['building'] = mapData(building)
      originList['floor'] = mapData(floor)
    }

    let currentState = {}
    const updateParams = () => {
      currentState = cloneDeep(formState);
    }
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };

    const mode = ref(['date', 'date']);
    const isOpen = ref(false);
    const openChange = (panelStatus) => {
      if (panelStatus) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
      }
    };
    const handlePanelChange = (val) => {
      if (formState.seatApplicationTime[1] && formState.seatApplicationTime[1]._d !== val[1]._d) {
        isOpen.value = false;
      }
      formState.seatApplicationEndTime = val[1]
      formState.seatApplicationBeginTime = val[0]
      formState.seatApplicationTime = [val[0].format('YYYY-MM-DD'), val[1].format('YYYY-MM-DD')]
    };

    watch(
      () => formState.seatApplicationTime,
      (val) => {
        if (val && val.length > 0) {
          formState.seatApplicationEndTime = val[1]
          formState.seatApplicationBeginTime = val[0]
        } else {
          formState.seatApplicationEndTime = ''
          formState.seatApplicationBeginTime = ''
        }
      }
    )

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = async (data) => {
      let params = {
        firstMenu: 'seatManagement',
        secondMenu: 'myApplication',
        isQueryDefault: data.isQueryDefault
      }
      await $api.queryFilterSort(params).then((res) => {
        if (data.isQuery) {
          queryList.value = res.data.filter((item) => {
            return item.isDeleted === '0'
          })
          queryList.value.length > 5 ? moreBtnVisible.value = true : moreBtnVisible.value = false
          checkList.value = [...queryList.value]
        } else {
          checkList.value = res.data.filter((item) => {
            return item.isDeleted === '0'
          })
        }
      });
      if (routeId.value) {
        ctx.emit("search", currentState);
      }
    };

    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = () => {
      isShowMoreList.value = false
    }
    const closeModal = () => {
      isShowFormSortBox.value = false
    }
    const getPlaceholder = (data) => {
      switch (data) {
        case 'condition':
        case 'groupLead':
          return 'Name/ID Number'
        default:
          return '请输入'  // 如果没
      }
    }
    const getOptions = (data) => {
      switch (data) {
        case 'seatApplicationStatus':
          return seatApplicationStatusOps.value
        case 'city':
          return optionList.city
        case 'building':
          return optionList.building
        case 'floor':
          return optionList.floor
        default:
          ''  // 如果没
      }
    }
    const disabledOption = (data) => {
      switch (data) {
        case 'building':
          return optionList.building && optionList.building.length == 0 ? true : false
        case 'floor':
          return optionList.floor && optionList.floor.length == 0 ? true : false
        default:
          false  // 如果没
      }
    }
    const getItemCode = (data) => {
      let code = data
      if (data == 'city') {
        code = 'City'
      } else if (data == 'building') {
        code = 'Building'
      } else if (data == 'floor') {
        code = 'Floor'
      }
      return code
    }
    const getChartContainerStyle = () => {
      ctx.emit("refresh-table-height");
    };

    const routeId = ref("")
    const route = useRoute();
    watch(
      () => route.query.id,
      (val) => {
        if (val) {
          Object.assign(formState, initData)
          routeId.value = route.query.id
          formState.seatApplicationId = route.query.id
          updateParams()
        }
        queryFormList({ isQueryDefault: 0, isQuery: true })
      },
      { immediate: true }
    )
    onBeforeRouteLeave(() => {
      //formState.seatApplicationId = ''
      route.query.flag = ''
      route.query.id = ''
    });

    onMounted(() => {
      getOptionHandler()
    })

    onActivated(() => {
      if(!routeId.value) {
        ctx.emit("refresh-table");
      }
    });
    return {
      formState,
      formRef,
      resetForm,
      doSearch,
      seatApplicationStatusOps,
      mode,
      isOpen,
      openChange,
      handlePanelChange,
      getChartContainerStyle,
      showMoreForm,
      queryFormList,
      queryList,
      checkList,
      moreBtnVisible,
      openFormSortBox,
      closeMoreForm,
      closeModal,
      isShowFormSortBox,
      isShowMoreList,
      getPlaceholder,
      getOptions,
      routeId,
      moreSeachHanlder,
      changeSelectOption,
      disabledOption,
      getItemCode
    };
  },
});
</script>
